import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { PaymentsService } from './services/payments.service';
import { HttpClientModule } from '@angular/common/http';
import { HelpService } from './services/help.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AngularFirestoreModule,
    HttpClientModule
  ],
  providers: [
    CanDeactivateGuard,
    AngularFirestore,
    AuthGuard,
    GuestGuard,
    AuthService,
    PaymentsService,
    HelpService
  ]
})
export class CoreModule { }
