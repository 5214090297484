import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from 'firebase';
import { switchMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: Observable<User>;

  constructor(private auth: AngularFireAuth, private router: Router, private db: AngularFirestore) {
    this.user = this.auth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db.doc<User>(`users/${user.uid}`).valueChanges().pipe(map(val => {
            return { uid: user.uid, email: user.email, ...val};
          }));
        } else {
          return of(null);
        }
      })
    );
  }

  getCurrentUser() {
    return this.auth.user;
  }

  createUserData(userId, name: string) {
    const newCompanyId = this.db.createId();
    const actions = Promise.all([
      this.db.collection('users').doc(userId).set({ name, companies: [newCompanyId] }),
      this.db.collection('companies').doc(newCompanyId).set({ name, owner: userId })
    ]);
    return actions;
  }

  updateCurrentUserEmail(email) {
    return this.auth.auth.currentUser.updateEmail(email);
  }

  logOut(sendToSignin = false) {
    let url = '/';
    if (sendToSignin) {
      url = '/login';
    }
    this.auth.auth.signOut().then(() => {
      this.router.navigateByUrl(url);
    });
  }

  signUp(email, password) {
    return this.auth.auth.createUserWithEmailAndPassword(email, password);
  }

  login(email, password) {
    return this.auth.auth.signInWithEmailAndPassword(email, password);
  }

  sendRecoverPassEmail(email) {
    return this.auth.auth.sendPasswordResetEmail(email);
  }

  confirmNewPasswordAndLogin(code, password) {
    return this.auth.auth.confirmPasswordReset(code, password);
  }

  deleteUser() {
    alert('TODO CALL CLOUD FUNCTION');
  }
}
