export const environment = {
  production: true,
  url: 'https://test.writeon.io/',
  apiUrl: 'https://us-central1-rewrite-untold.cloudfunctions.net/api/',
  stripe: {
    key: 'pk_test_diGS3Jf6nAetlfk8RifTvZPP'
  },
  firebase: {
    apiKey: 'AIzaSyCSA5cYSR57loFMQkA1OrY3dKYaqZtwmhk',
    authDomain: 'rewrite-untold.firebaseapp.com',
    databaseURL: 'https://rewrite-untold.firebaseio.com',
    projectId: 'rewrite-untold',
    storageBucket: 'rewrite-untold.appspot.com',
    messagingSenderId: '925331224379'
  }
};
