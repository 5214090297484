import { ApiGatewayService } from './api-gateway.service';
import * as i0 from "@angular/core";
import * as i1 from "./api-gateway.service";
var PaymentsService = /** @class */ (function () {
    function PaymentsService(api) {
        this.api = api;
    }
    PaymentsService.prototype.updatePlan = function (companyId, planId, stripeToken, vatNumber) {
        return this.api.post("updatePlan/" + companyId, { planId: planId, stripeToken: stripeToken, vatNumber: vatNumber });
    };
    PaymentsService.prototype.updateCard = function (companyId, stripeToken) {
        return this.api.post("updateCard/" + companyId, { stripeToken: stripeToken });
    };
    PaymentsService.prototype.cancelPlan = function (companyId) {
        return this.api.delete("plan/" + companyId);
    };
    PaymentsService.prototype.updateUserInfo = function (companyId, payload) {
        return this.api.post("user/updateInfo/" + companyId, payload);
    };
    PaymentsService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentsService_Factory() { return new PaymentsService(i0.inject(i1.ApiGatewayService)); }, token: PaymentsService, providedIn: "root" });
    return PaymentsService;
}());
export { PaymentsService };
