import { Component, OnInit } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {
    if (environment.production) {
      angulartics2GoogleAnalytics.startTracking();
    }
  }

  ngOnInit() {
  }
}
