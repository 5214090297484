import { CanDeactivate } from '@angular/router';
import * as i0 from "@angular/core";
var CanDeactivateGuard = /** @class */ (function () {
    function CanDeactivateGuard() {
    }
    CanDeactivateGuard.prototype.canDeactivate = function (component) {
        if (component.task && component.task.workingChanges && component.task.workingChanges.length > 0) {
            if (confirm('Leave site? Changes that you made may not be saved.')) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    };
    CanDeactivateGuard.ngInjectableDef = i0.defineInjectable({ factory: function CanDeactivateGuard_Factory() { return new CanDeactivateGuard(); }, token: CanDeactivateGuard, providedIn: "root" });
    return CanDeactivateGuard;
}());
export { CanDeactivateGuard };
