import { Injectable } from '@angular/core';

declare var $crisp: any;

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  constructor() { }

  openCrisp() {
    $crisp.push(['do', 'chat:show']);
    $crisp.push(['do', 'chat:open']);
    $crisp.push(['on', 'chat:closed', () => {
      $crisp.push(['do', 'chat:hide']);
    }]);
  }
}
