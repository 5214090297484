import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: './landing/landing.module#LandingModule' },
  { path: 'login', loadChildren: './landing/landing.module#LandingModule' },
  { path: 'logout', loadChildren: './landing/landing.module#LandingModule' },
  { path: 'signup', loadChildren: './landing/landing.module#LandingModule' },
  { path: 'recover', loadChildren: './landing/landing.module#LandingModule' },
  { path: 'terms', loadChildren: './landing/landing.module#LandingModule' },
  { path: 'style', loadChildren: './landing/landing.module#LandingModule' },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'viewer/:hash',
    loadChildren: './viewer/viewer.module#ViewerModule',
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
