import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
var ApiGatewayService = /** @class */ (function () {
    function ApiGatewayService(afAuth, http, router) {
        var _this = this;
        this.afAuth = afAuth;
        this.http = http;
        this.router = router;
        this.afAuth.idToken.subscribe(function (token) {
            _this.token = token;
        });
    }
    ApiGatewayService.prototype.post = function (endpoint, params) {
        var _this = this;
        var hoptions = {
            headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token)
        };
        return this.http.post(environment.apiUrl + endpoint, params, hoptions).pipe(catchError(function (err) { return (_this.handleError(err)); }));
    };
    ApiGatewayService.prototype.delete = function (endpoint) {
        var _this = this;
        var hoptions = {
            headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token)
        };
        return this.http.delete(environment.apiUrl + endpoint, hoptions).pipe(catchError(function (err) { return (_this.handleError(err)); }));
    };
    ApiGatewayService.prototype.handleError = function (error) {
        var errMsg = 'Ops, something went really wrong!';
        if (error.error && error.error.errors) {
            errMsg = '';
            for (var _i = 0, _a = error.error.errors; _i < _a.length; _i++) {
                var e = _a[_i];
                errMsg = errMsg + e + '\n';
            }
        }
        if (error.error && error.error.data) {
            return throwError({ errMsg: errMsg, errData: error.error.data });
        }
        return throwError(errMsg);
    };
    ApiGatewayService.ngInjectableDef = i0.defineInjectable({ factory: function ApiGatewayService_Factory() { return new ApiGatewayService(i0.inject(i1.AngularFireAuth), i0.inject(i2.HttpClient), i0.inject(i3.Router)); }, token: ApiGatewayService, providedIn: "root" });
    return ApiGatewayService;
}());
export { ApiGatewayService };
