import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/router";
var GuestGuard = /** @class */ (function () {
    function GuestGuard(fireAuth, router) {
        this.fireAuth = fireAuth;
        this.router = router;
    }
    GuestGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.fireAuth.user.pipe(take(1), map(function (user) {
            if (user) {
                _this.router.navigate(['/dashboard']);
                return false;
            }
            return true;
        }));
    };
    GuestGuard.prototype.canActivateChild = function (next, state) {
        return this.canActivate(next, state);
    };
    GuestGuard.ngInjectableDef = i0.defineInjectable({ factory: function GuestGuard_Factory() { return new GuestGuard(i0.inject(i1.AngularFireAuth), i0.inject(i2.Router)); }, token: GuestGuard, providedIn: "root" });
    return GuestGuard;
}());
export { GuestGuard };
