import { Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private api: ApiGatewayService) { }

  updatePlan(companyId, planId, stripeToken, vatNumber?) {
    return this.api.post(`updatePlan/${companyId}`, {planId, stripeToken, vatNumber});
  }

  updateCard(companyId, stripeToken) {
    return this.api.post(`updateCard/${companyId}`, { stripeToken });
  }

  cancelPlan(companyId) {
    return this.api.delete(`plan/${companyId}`);
  }

  updateUserInfo(companyId, payload) {
    return this.api.post(`user/updateInfo/${companyId}`, payload);
  }

}
