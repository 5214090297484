import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, from, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiGatewayService {

  private token: string;

  constructor(private afAuth: AngularFireAuth, private http: HttpClient, private router: Router) {
    this.afAuth.idToken.subscribe(token => {
      this.token = token;
    });
  }

  post(endpoint: string, params?: any): Observable<any> {
    const hoptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token)
    };

    return this.http.post(environment.apiUrl + endpoint, params, hoptions).pipe(catchError(err => (this.handleError(err))));
  }

  delete(endpoint: string): Observable<any> {
    const hoptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token)
    };

    return this.http.delete(environment.apiUrl + endpoint, hoptions).pipe(catchError(err => (this.handleError(err))));
  }


  public handleError(error: Response | any) {
    let errMsg = 'Ops, something went really wrong!';

    if (error.error && error.error.errors) {
      errMsg = '';
      for (const e of error.error.errors) {
        errMsg = errMsg + e + '\n';
      }
    }

    if (error.error && error.error.data) {
      return throwError({ errMsg, errData: error.error.data});
    }


    return throwError(errMsg);
  }

}
