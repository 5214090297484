import * as i0 from "@angular/core";
var HelpService = /** @class */ (function () {
    function HelpService() {
    }
    HelpService.prototype.openCrisp = function () {
        $crisp.push(['do', 'chat:show']);
        $crisp.push(['do', 'chat:open']);
        $crisp.push(['on', 'chat:closed', function () {
                $crisp.push(['do', 'chat:hide']);
            }]);
    };
    HelpService.ngInjectableDef = i0.defineInjectable({ factory: function HelpService_Factory() { return new HelpService(); }, token: HelpService, providedIn: "root" });
    return HelpService;
}());
export { HelpService };
