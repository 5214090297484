import * as tslib_1 from "tslib";
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/router";
import * as i3 from "@angular/fire/firestore";
var AuthService = /** @class */ (function () {
    function AuthService(auth, router, db) {
        var _this = this;
        this.auth = auth;
        this.router = router;
        this.db = db;
        this.user = this.auth.authState.pipe(switchMap(function (user) {
            if (user) {
                return _this.db.doc("users/" + user.uid).valueChanges().pipe(map(function (val) {
                    return tslib_1.__assign({ uid: user.uid, email: user.email }, val);
                }));
            }
            else {
                return of(null);
            }
        }));
    }
    AuthService.prototype.getCurrentUser = function () {
        return this.auth.user;
    };
    AuthService.prototype.createUserData = function (userId, name) {
        var newCompanyId = this.db.createId();
        var actions = Promise.all([
            this.db.collection('users').doc(userId).set({ name: name, companies: [newCompanyId] }),
            this.db.collection('companies').doc(newCompanyId).set({ name: name, owner: userId })
        ]);
        return actions;
    };
    AuthService.prototype.updateCurrentUserEmail = function (email) {
        return this.auth.auth.currentUser.updateEmail(email);
    };
    AuthService.prototype.logOut = function (sendToSignin) {
        var _this = this;
        if (sendToSignin === void 0) { sendToSignin = false; }
        var url = '/';
        if (sendToSignin) {
            url = '/login';
        }
        this.auth.auth.signOut().then(function () {
            _this.router.navigateByUrl(url);
        });
    };
    AuthService.prototype.signUp = function (email, password) {
        return this.auth.auth.createUserWithEmailAndPassword(email, password);
    };
    AuthService.prototype.login = function (email, password) {
        return this.auth.auth.signInWithEmailAndPassword(email, password);
    };
    AuthService.prototype.sendRecoverPassEmail = function (email) {
        return this.auth.auth.sendPasswordResetEmail(email);
    };
    AuthService.prototype.confirmNewPasswordAndLogin = function (code, password) {
        return this.auth.auth.confirmPasswordReset(code, password);
    };
    AuthService.prototype.deleteUser = function () {
        alert('TODO CALL CLOUD FUNCTION');
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.AngularFireAuth), i0.inject(i2.Router), i0.inject(i3.AngularFirestore)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
