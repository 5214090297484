import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewerComponent } from 'src/app/viewer/viewer.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ViewerComponent> {
  canDeactivate(component: ViewerComponent): boolean {

    if (component.task && component.task.workingChanges && component.task.workingChanges.length > 0) {
      if (confirm('Leave site? Changes that you made may not be saved.')) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
